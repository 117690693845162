<template>
  <div class="case-left-wrap">
    <div class="content-wraps" >
      <div class="case-title">全部案例</div>
      <div class="case-list" v-if="typeList.length > 0">
        <div class="case-list-item" v-for="(item, index) in typeList"
          :key="index" @click="posterDetail(item,index)" :class="isPoster == index ? 'activePoster' : ''" >
            <el-image class="case-img" v-if="item.caseImgUrlList" :src="item.caseImgUrlList[0].url" lazy-load mode="aspectFill"></el-image>
            <div class="item-title">{{ item.caseTitle }}</div>
            <div class="item-copy">{{ item.caseDescription }}</div>
            <div class="del" @click="clickDelete(item)"><i class="el-icon-delete"></i></div>
          </div>
      </div>
      <div v-else class="case-list">
        <div class="case-list-item back" v-for="(item, index) in 4"
          :key="index" @click="posterDetail(item,index)" :class="isPoster == index ? 'activePoster' : ''"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      typeList: [],
      isPoster: -1,
      delIds: null
    };
  },
  mounted(){
    this.getShopCaseList();
  },
  methods: {
    setValueCase(value){
      if(value){
        this.typeList = value
      }
    },
    // 获取案例列表
    getShopCaseList(){
      this.$API.getShopCaseList().then( res => {
        console.log(res.data);
          if(res.code === 0){
            this.typeList = res.data.list
          }
      })
    },
    posterDetail(item,index) {
      this.delIds = item.id
      this.isPoster = index;
    },
    //删除
    clickDelete(item){
      console.log(item);
      let ids = [];
      ids.push(item.id)
      let params ={
        ids: ids
      }
      this.$API.deleteHomeCase(params).then( res => {
          if(res.code === 0){
            this.getShopCaseList();
            this.isPoster = -1;
            this.$message.success("删除案例成功");
          }
      })
    }
  },
};
</script>
<style lang="scss">
.case-left-wrap {
  width: 370px;
  height: 90%;
  background: #fff;
  margin: 0px auto;
  box-shadow: 0 0px 1px 1px rgba(100, 100, 100, 0.1);
  .content-wraps {
    height: 100%;
    padding: 0 10px;
    overflow-y: auto;
    .case-title {
      padding: 14px 0;
      font-size: 15px;
      color: #000;
      font-weight: bold;
    }
    .case-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      box-sizing: border-box;
      .back{background: #eee;}
      .case-list-item {
        width: calc((100% - 10px) / 2);
        display: flex;
        flex-direction: column;
        color: #313233;
        border: 1px solid #fff;
        margin-bottom: 5px;
        position: relative;
        min-height: 110px;
        .case-img {
          width: 100%;
          height: 110px;
          border-radius: 4px;
          vertical-align: bottom;
        }
        .item-title {
          font-size: 14px;
          font-weight: 600;
          padding-top: 5px;
          padding-bottom: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item-copy {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 5px;
        }
        .del{
          position: absolute;
          top: 0px;
          right: 0px;
          height: 110px;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          display: none;
          cursor: pointer;
          .el-icon-delete{
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
      .case-list-item:hover{
          .del{
            display: block;
          }
      }
    }
  }
  .activePoster {
    border: 1px solid rgb(103, 155, 252) !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #c1c1c1;
    background-color: rgba(239, 243, 248, 0.9);   //滚动框颜色
  }
  ::-webkit-scrollbar-track   {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(239, 243, 248, 0.3);   //滚动条颜色
      background-color: #c5c8cd;
  }
  ::-webkit-scrollbar-thumb:hover {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.1);
  }  
}
</style>