<!-- 店铺-首页-右-产品 -->
<template>
    <div class="homeRightProduct">
        <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
                <span>添加案例</span>
            </div>
            <div class="box-item">
                <el-button @click="dialogTableVisible = true">+添加案例与商品</el-button>
            </div>
        </el-card>
        <div class="dialog-wraps">
            <el-dialog title="添加案例" :visible.sync="dialogTableVisible" width="70%">
                <div class="dialog-wrap">
                  <div class="dialog-item-left">
                    <div class="add-img">
                      <span>添加图片:</span>
                       <el-button class="upload-demo" @click="uploadMainImage">+添加图片</el-button>
                    </div>
                    <div v-if="caseImgList.length != 0" >
                      <el-image class="iamges-wrap" :src="caseImgList[0].url"></el-image>
                    </div>
                    <div class="add-title">
                      <div class="add-title-item">
                        <span>案例名称:</span>
                        <el-input v-model="caseTitle" placeholder="请输入案例名称"></el-input>
                      </div>
                      <div class="add-title-item">
                        <span>案例描述:</span>
                        <el-input v-model="caseDescription" placeholder="请输入案例描述"></el-input>
                      </div>
                    </div>
                  </div>

                  <div class="dialog-item-right">
                    <div class="filter-wrap">
                      <div class="filter-item">
                          <div class="filter-title">商品名称:</div>
                          <el-input v-model="goodsName" placeholder="输入商品名称、商品编码" @input="changeInput"></el-input>
                      </div>
                      <div class="filter-item">
                          <div class="filter-title">商品类目:</div>
                          <el-cascader
                            @change="handleChange"
                            :options="options"
                            :props="{ checkStrictly: true }"
                            clearable
                            size="small"
                          ></el-cascader>
                          <!-- <el-cascader
                              v-model="value"
                              :options="options"
                              @change="handleChange">
                          </el-cascader> -->
                      </div>
                    </div>
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" height="300"
                        style="width: 100%"  @selection-change="handleSelectionChange" :row-key="getRowKeyOfTarget">
                        <el-table-column type="selection" reserve-selection width="55"></el-table-column>
                        <el-table-column prop="goodsNo" label="商品编号"></el-table-column>
                        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                        <el-table-column label="商品图片" width="120">
                            <template slot-scope="scope">
                            <img :src="scope.row.masterImageUrl" alt="" width="60" height="40" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="价格(元)" ></el-table-column>
                        <el-table-column label="库存">
                            <template slot-scope="scope">
                            <div>{{scope.row.inventory}} {{scope.row.units}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="collen" label="收藏" ></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip ></el-table-column>
                    </el-table>
                    <div class="page-wrap">
                        <div class="choose-all">
                            <!-- <el-checkbox v-model="checked">当页全选</el-checkbox> -->
                        </div>
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currpage"
                            :page-sizes="[5, 10, 20, 50,100]"
                            :page-size="pagesize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.length">
                        </el-pagination>
                    </div>
                  </div>
                </div>
                <div class="btn-wrap">
                    <el-button type="primary" @click="clickChoose">确定</el-button>
                    <el-button @click="toggleSelection()">取消</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 选择视频图片弹窗 -->
        <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
    </div>
</template>

<script>
import uploadData from '@/components/uploadData'
export default {
  components:{
      uploadData
  },
  data () {
    return {
        pagesize: 5,
        currpage: 1,
        pageInfo: 0,
        graphics: 100,  //上传图片数量
        uploadType: '',  //上传图片类型

        caseImgList: [],
        caseTitle:"",
        caseDescription: "",
        goodsList: [],
        catalogId: null,
        checked: true,
        goodsName: '',
        tableData: [],
        dialogTableVisible: false,
        value: [],
        options: [],
    };
  },
  mounted(){
    this.getGoodsListbyBrand();
    this.getListCatalogType();
  },
  methods: {
    //搜索
    changeInput(e){
      this.goodsName = e
      this.getGoodsListbyBrand()
    },
    //获取商品列表
    getGoodsListbyBrand(){
        let params = {
            keyword: this.goodsName,
            currentPage: this.currpage,
            pageSize: this.pagesize,
            status: '上架中',
            catalogId: this.catalogId
        }
        this.$API.getGoodsByShopList(params).then( res => {
            if(res.code === 0){
              this.tableData = res.data.list
              this.pageInfo = res.data.pageInfo.totalRow
            }
        })
    },
    // 获取商品类目结构
    getListCatalogType(){
      this.$API.getListCatalogType().then( res => {
          if(res.code === 0){
              let resultSecond;
              var result = res.data.map(o=>{
                  if(o.childrenNode){
                      resultSecond =  o.childrenNode.map(c=>{
                          return{
                              value: c.data.id, 
                              label: c.data.catalogName,
                          }
                      })
                  }
                  return{
                      value: o.data.id, 
                      label: o.data.catalogName,
                      children: resultSecond
                  }
              });
              this.options = result;
          }
      })
    },
    //上传主图
    uploadMainImage(){
        this.graphics = 1;
        this.uploadType = 'case'
        this.$refs.uploadData.getDate()
    },
    //选中图片
    selectImages(value,type){
        switch (type) {
        case 'case':
            this.caseImgList = value;
            break;
        default:
            break;
        }
        this.$emit('swiperChang',this.indexImgList)
    },
    // 确定添加案例
    clickChoose(){
      let caseIds = [];
      this.caseImgList.forEach(v =>{
        caseIds.push(v.id)
      })
      let params = {
        caseImgList: caseIds,
        caseTitle: this.caseTitle,
        caseDescription: this.caseDescription,
        goodsList: this.goodsList,
      }
      if(this.caseImgList.length > 0 && this.goodsList.length>0){
        this.$API.addHomeCase(params).then( res => {
          if(res.code === 0){
            this.getShopCaseList();
            this.$message.success("添加案例成功");
            this.dialogTableVisible = false;
            this.caseImgList = [];
            this.caseTitle = "";
            this.caseDescription = "";
            this.$refs.multipleTable.clearSelection();
          }
        })
      }
    },
    getShopCaseList(){
      this.$API.getShopCaseList().then( res => {
          if(res.code === 0){
            this.typeList = res.data.list
            this.$emit("caseList",res.data.list)
          }
      })
    },
    //取消
    toggleSelection() {
        this.$refs.multipleTable.clearSelection();
        this.caseImgList = [];
        this.caseTitle = "";
        this.caseDescription = "";
        this.dialogTableVisible = false
    },
    //选中的值
    handleSelectionChange(val) {
        let productList = [];
        val.forEach(v => {
          productList.push(v.id)
        });
        this.goodsList = productList
    },
    getRowKeyOfTarget(row) {
        return row.id
    },
    //选择商品类目
    handleChange(val){
        this.catalogId = val[val.length-1];
        this.getGoodsListbyBrand();
    },
    handleCurrentChange(cpage) {
        this.currpage = cpage;
        this.getGoodsListbyBrand();
    },
    handleSizeChange(psize) {
        this.pagesize = psize;
        this.getGoodsListbyBrand();
    },
  }
}

</script>
<style lang='scss' scoped>
.homeRightProduct{
    .box-card{
        width: 100%;
        margin: 0;
        .box-item{
            .item-title{
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
            .item-content{
                font-size: 14px;
                color: #999;
                padding: 10px 0;
            }
        }
        .el-button{
            width: 100%;
        }
    }
    .dialog-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eee;
        padding: 0 20px;
        .dialog-item-left{
          flex: 1;
          .add-img{
            .upload-demo{
              margin-top: 20px;
              margin-left: 10px;
              .el-upload--picture-card{
                width: 100px !important;
                height: 20px !important;
              }
            }
          }
          .iamges-wrap{
            margin-top: 20px;
            height: 260px;
          }
          .add-title{
            .add-title-item{
              display: flex;
              flex-flow: wrap;
              align-items: center;
              margin: 20px 0;
              .el-input{
                width: 200px;
                margin-left: 10px;
              }
            }
          }
        }
        .dialog-item-right{
          flex: 2;
          border-left: 1px solid #eee;
        }
        .filter-wrap{
            display: flex;
            flex-flow: wrap;
            align-items: center;
            .filter-item{
                display: flex;
                flex-flow: wrap;
                align-items: center;
                padding-right: 40px;
                padding-top: 15px;
                .el-input{
                    width: 200px;
                }
                .filter-title{
                    padding-right: 10px;
                }
            }
        }
        .page-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            .choose-all{
                padding-left: 15px;
            }
        }
        
    }
    .btn-wrap{
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        .el-button{
            width: 100px !important;
        }
    }
}

</style>